import mixpanel, { type RequestOptions } from 'mixpanel-browser';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import tryJSONParse from 'api_measure/lib/tryJSONParse';

import { useCurrentUser } from 'src/data/user/useCurrentUser';
import { delay } from 'src/util/delay';

import config from '~/config';

const mixpanelSettings = {
  initialized: false,
  disabled: !config.mixpanel.token,
};

/**
 * Any emails that match these regexes will not be tracked in Mixpanel.
 */
const emailBlacklist = [/rainforestqa\.com$/];

const trackedEvents: Record<string, boolean> = {};

/**
 * Types of events you can pass to track(), and additional parameter types for each event.
 * See descriptions of each event on the wiki:
 * https://confluence.cancerlinq.org/display/DEV/Mixpanel
 */
export type EventTypes = {
  'App Load': undefined;

  'Page URL:Enter': undefined;
  'Page URL:Exit': undefined;

  'Page View:Enter': undefined;
  'Page View:Exit': undefined;

  'Page Section:Enter': undefined;
  'Page Section:Exit': undefined;

  'Duration': { Duration: 0 | 1 };

  'Session Timeout': undefined;

  'Log Out': undefined;

  'Navigation Menu': { Label: string };

  'PHI Export': { Count: '500' | 'All'; Page: number };

  'Modal:Open': { Label: string; Detail?: any };
  'Modal:Close': { Label: string; Detail?: any };

  'Popup': { Label: string };

  'Menu Click': { Label: string; Context?: string };

  'Button': { Label: string; Context?: string };

  'Dropdown': {
    Label: string;
    Context?: string;
    Value?: string | number;
    InternalValue?: string | number;
  };

  'Slider': { Label: string; Value1: string | number; Value2: string | number };

  'Widget': { Label: string; Detail?: any };

  'Table:Filter': { Column: string };
  'Table:Sort': { Column: string; Direction: 'asc' | 'desc' };
  'Table:Click Row': undefined;
};

export type EventType = keyof EventTypes;

/**
 * Gets the type of parameters for a given event type
 */
export type EventParams<T extends EventType> = EventTypes[T];

/**
 * Union of event types that require parameters
 */
type EventTypeWithParams = {
  [P in EventType]: EventTypes[P] extends undefined ? never : P;
}[EventType];

/**
 * Union of event types that do not require parameters
 */
type EventTypeWithoutParams = {
  [P in EventType]: EventTypes[P] extends undefined ? P : never;
}[EventType];

/**
 * Track events that require parameters.
 */
export default async function track<T extends EventTypeWithParams>(
  type: T,
  params: EventParams<T>,
  options?: RequestOptions,
): Promise<void>;

/**
 * Track events that do not require parameters.
 */
export default async function track<T extends EventTypeWithoutParams>(
  type: T,
  params?: null,
  options?: RequestOptions,
): Promise<void>;

/**
 * Type-safe, async version of mixpanel.track.
 */
export default async function track<T extends EventType>(
  type: T,
  params?: EventParams<T>,
  options?: RequestOptions,
): Promise<void> {
  return new Promise((accept) => {
    if (mixpanelSettings.disabled) {
      accept();
      return;
    }
    if (!mixpanelSettings.initialized) {
      (async () => {
        await delay(500);
        await track<any>(type, params, options);
        accept();
      })();
      return;
    }
    const globals = getMixpanelGlobals();
    mixpanel.track(
      type,
      {
        'Path': window.location.pathname,
        'Query': window.location.search,
        'Is First': !trackedEvents[type],
        ...globals,
        ...params!,
      },
      options,
      () => accept(),
    );
    trackedEvents[type] = true;
  });
}

export function timeEvent(event: EventType) {
  if (mixpanelSettings.disabled) return;
  if (!mixpanelSettings.initialized) {
    setTimeout(() => timeEvent(event), 500);
    return;
  }
  mixpanel.time_event(event);
}

interface GlobalPropertyTypes {
  /**
   * The current page title. This is set by the PageTitle component.
   */
  'Page Title': string;

  /**
   * The current page category. This is set by the PageTitle component.
   */
  'Page Category': string;

  /**
   * The active page section. This is used to help refine the visible area of a page that was
   * active when an event occurs, such as an active tab.
   */
  'Page Section': string;

  /**
   * Email template source for the current session. This corresponds to one of the email template
   * keys defined in api_measure. This is set by the `mp_email_template` query parameter on first
   * page load, which is included in urls in the email template content. This is used to track where
   * users are coming from, such as a specific email campaign. This value will be set upon page load
   * and then persisted for the entire session.
   */
  'Email Template': string;

  /**
   * Email template detail for the current session. This is an additional value defined by the email
   * template in api_measure, used to further identify the email type if it has additional
   * parameters when sending. This is set by the `mp_email_detail` query parameter on first page
   * load, which is included in urls in the email template content. This is used to track where
   * users are coming from, such as a specific email campaign. This value will be set upon page load
   * and then persisted for the entire session.
   */
  'Email Detail': string;
}

type GlobalProperty = keyof GlobalPropertyTypes;
type GlobalPropertyValue<T extends GlobalProperty> = GlobalPropertyTypes[T];

const windowGlobals: Record<string, any> = {};

/**
 * Registers a global property. Global properties are set once and then sent with every subsequent
 * event. They can be used to track ambient information such as the current page title or subsection
 * where an event might occur.
 *
 * If you use this, be sure to unregister (with `trackGlobal(property, null)`) to remove properties when they are no longer relevant,
 * such as on component unmount.
 *
 * We use our own implementation of this (instead of mixpanel's super properties) because mixpanel's implementation
 * only sets properties in a cookie, which applies to all open tabs and persists across page loads. We need more flexibility,
 * such as setting properties on a per-tab basis and clearing them when the tab is closed.
 */
export function trackGlobal<T extends GlobalProperty>(
  name: T,
  value: GlobalPropertyValue<T> | null | undefined,
  storage: 'window' | 'session' | 'local' = 'window',
) {
  if (mixpanelSettings.disabled) return;
  if (storage === 'window') {
    if (value === null) delete windowGlobals[name];
    else windowGlobals[name] = value;
  } else {
    const mixpanelSession = getMixpanelStorage(storage === 'local' ? localStorage : sessionStorage);
    if (value === null) delete mixpanelSession[name];
    else mixpanelSession[name] = value;
    sessionStorage.setItem('mixpanel', JSON.stringify(mixpanelSession));
  }
}

const getMixpanelStorage = (storage: Storage): Record<string, any> =>
  tryJSONParse(storage.getItem('mixpanel-session') || '{}', {});

const getMixpanelGlobals = (): Record<string, any> => ({
  ...getMixpanelStorage(localStorage),
  ...getMixpanelStorage(sessionStorage),
  ...windowGlobals,
});

/**
 * Component that registers mixpanel tracking. This is just mounted once at the top level of the app.
 * It does not render any UI.
 */
export function MixpanelNavigationTracker() {
  const currentUser = useCurrentUser();

  useEffect(() => {
    // Don't track anything before the user is loaded or if mixpanel is disabled
    if (!currentUser.profile || mixpanelSettings.disabled) return;

    // Don't track users in the email blacklist
    if (emailBlacklist.some((regex) => regex.test(currentUser.profile.email))) {
      mixpanelSettings.disabled = true;
      return;
    }

    // Initialize mixpanel
    mixpanel.init(config.mixpanel.token, { debug: config.mixpanel.debug });
    mixpanelSettings.initialized = true;

    // Identify and set user properties
    mixpanel.identify(String(currentUser.profile.id));
    mixpanel.people.set({
      '$email': currentUser.profile.email,
      '$name': `${currentUser.profile.first_name} ${currentUser.profile.last_name}`,
      '$last_login': new Date(),
      'Org Code': currentUser.profile.org_owner,
      'Org Name': currentUser.userOrg.name,
      'Org Features': currentUser.userOrg.features || [],
      'Org Type': currentUser.userOrg.type,
      'Org Categories': currentUser.userOrg.categories || [],
      'Roles': currentUser.roles || [],
      'QOPI': currentUser.userOrg.qopi_signup_type,
    });

    // Track when user loads page
    track('App Load');
  }, [currentUser.profile]);

  // Track page views when url changes
  const location = useLocation();
  useEffect(() => {
    track('Page URL:Enter');
    timeEvent('Page URL:Exit');

    return () => {
      track('Page URL:Exit');
    };
  }, [location.pathname]);

  // Track durations
  // This is meant as a fallback for when the page is closed before a "Page View:Exit" event is sent.
  useEffect(() => {
    // A single event with Duration: 0 is sent on every page view after 3 seconds (to wait for the page title to load)
    const timeout = setTimeout(() => {
      track('Duration', { Duration: 0 }, { send_immediately: true, transport: 'sendBeacon' });
    }, 3000);

    // An event with Duration: 1 is sent every minute to track page times
    const interval = setInterval(() => {
      track('Duration', { Duration: 1 }, { send_immediately: true, transport: 'sendBeacon' });
    }, 1000 * 60);

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, [location.pathname]);

  // Track email source parameters
  useEffect(() => {
    if (!location.search) return;

    const q = new URLSearchParams(location.search);

    const mp_email_template = q.get('mp_email_template');
    if (mp_email_template) {
      trackGlobal('Email Template', mp_email_template);
    }

    const mp_email_detail = q.get('mp_email_detail');
    if (mp_email_detail) {
      trackGlobal('Email Detail', mp_email_detail);
    }
  }, [location.search]);

  return null;
}

export function useTrackPageTitle(title?: string, category?: string) {
  useEffect(() => {
    if (!title) return;

    trackGlobal('Page Title', title);
    trackGlobal('Page Category', category || title);
    track('Page View:Enter');
    timeEvent('Page View:Exit');

    return () => {
      track('Page View:Exit');
      trackGlobal('Page Title', null);
      trackGlobal('Page Category', null);
    };
  }, [title, category || title]);
}

import { type IconDefinition } from '@fortawesome/fontawesome-svg-core';
import $, { useClassifyProps, type StylixProps } from '@stylix/core';
import color from 'color';
import { type To } from 'history';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import track from 'src/data/mixpanel';
import colors from 'src/ui/colors';

import Icon, { type IconProps } from './Icon';

interface CoreButtonProps {
  // button-set and button-set-selected are automatically applied when this button is a child of a button-set
  variant?:
    | 'blue'
    | 'blue-outline'
    | 'white-outline'
    | 'gold'
    | 'red'
    | 'ghost'
    | 'round'
    | 'round-ghost'
    | 'round-gray'
    | 'round-white'
    | 'button-set'
    | 'button-set-selected';
  large?: boolean;
  small?: boolean;
  faIcon?: IconDefinition;
  title?: string;
  linkTo?: To;
  iconProps?: Partial<IconProps>;
  trackingLabel?: string;
  trackingContext?: string;
  children?: any;
}

const styles = {
  button: {
    '&:focus, &:active': {
      outline: '1px dotted #2d72d299 !important',
      outlineOffset: 2,
    },
    '&::-moz-focus-inner': {
      border: 0,
    },
    '&:disabled': {
      opacity: 0.5,
      pointerEvents: 'none',
    },
  },

  rect: {
    'height': 40,
    'padding': '0 25px',
    'borderRadius': 3,
    'fontSize': 14,

    'svg.Button-icon': {
      marginRight: 10,
    },
  },

  rectLarge: {
    height: 44,
    padding: '0 40px',
    fontSize: 16,
  },

  rectSmall: {
    height: 32,
    padding: '0 15px',
    fontSize: 13,
    svg: {
      fontSize: 14,
    },
  },

  blue: {
    'color': colors.white.primary,
    'bgColor': colors.blue.primary,
    'border': `2px solid ${colors.blue.primary}`,
    '&:hover:not(:disabled)': {
      bgColor: colors.blue.primaryHover,
      borderColor: colors.blue.primaryHover,
    },
  },

  blueOutline: {
    'color': colors.blue.primary,
    'bgColor': 'transparent',
    'border': `2px solid ${colors.blue.primary}`,
    '&:hover:not(:disabled)': {
      bgColor: color(colors.blue.primary).alpha(0.1).string(),
    },
  },

  whiteOutline: {
    'color': 'white',
    'bgColor': 'transparent',
    'border': `2px solid white`,
    '&:hover:not(:disabled)': {
      bgColor: '#FFF4',
    },
  },

  gold: {
    'color': colors.white.primary,
    'bgColor': colors.orange.primary,
    'border': `2px solid ${colors.orange.primary}`,
    '&:hover:not(:disabled)': {
      bgColor: colors.orange.medium,
      borderColor: colors.orange.medium,
    },
  },

  red: {
    'color': colors.white.primary,
    'bgColor': colors.red.primary,
    'border': `2px solid ${colors.red.primary}`,
    '&:hover:not(:disabled)': {
      bgColor: colors.red.medium,
      borderColor: colors.red.medium,
    },
  },

  ghost: {
    'color': colors.grey.neutral,
    'bgColor': '#FFF8',
    'border': `2px solid #0002`,
    '&:hover:not(:disabled)': {
      borderColor: colors.blue.primary,
    },
  },

  round: {
    'color': colors.white.primary,
    'bgColor': colors.blue.primaryHover,
    'background': `linear-gradient(to bottom, #64CAF1, #359CE1)`,
    'border': 0,
    'borderRadius': 100,
    'alignCenter': true,
    'padding': 0,
    'height': 36,
    'width': 36,
    'fontSize': 16,
    '&:hover:not(:disabled)': {
      background: `linear-gradient(to bottom, #4EA9CC, #247CB7)`,
    },
  },

  roundLarge: {
    height: 56,
    width: 56,
    fontSize: 22,
  },
  roundSmall: {
    height: 32,
    width: 32,
    fontSize: 14,
  },

  roundGhost: {
    'color': colors.grey.neutral,
    'background': 'transparent',
    '&:hover:not(:disabled)': {
      background: 'rgba(0, 0, 0, 0.075)',
    },
    '&:active:not(:disabled)': {
      background: 'rgba(0, 0, 0, 0.15)',
    },
    '&:focus': {
      borderColor: '#0006',
    },
  },
  roundGray: {
    'color': colors.grey.neutral,
    'background': '#0000000A',
    '&:hover:not(:disabled)': {
      background: '#00000020',
    },
  },
  roundWhite: {
    'color': colors.blue.primary,
    'background': '#FFF',
    'box-shadow': '0 4px 8px rgba(0,0,0,0.2)',
    '&:hover:not(:disabled)': {
      background: '#DDD',
    },
  },

  buttonSet: {
    'color': colors.grey.neutral,
    'bgColor': 'transparent',
    'border': 0,
    'borderRight': `2px solid ${colors.grey.disabledBorder}`,
    'borderRadius': 0,
    '&:last-of-type': {
      borderRight: 0,
    },
    '&:hover:not(:disabled)': {
      backgroundColor: colors.grey.disabledBorder,
    },
  },

  buttonSetSelected: {
    color: colors.white.primary,
    backgroundImage: `linear-gradient(to bottom, ${colors.red.medium}, ${colors.red.dark})`,
  },
};

const variantStylesMap: Record<Exclude<CoreButtonProps['variant'], undefined>, any[]> = {
  'blue': [styles.rect, styles.blue],
  'blue-outline': [styles.rect, styles.blueOutline],
  'white-outline': [styles.rect, styles.whiteOutline],
  'gold': [styles.rect, styles.gold],
  'red': [styles.rect, styles.red],
  'ghost': [styles.rect, styles.ghost],
  'round': [styles.round],
  'round-ghost': [styles.round, styles.roundGhost],
  'round-gray': [styles.round, styles.roundGray],
  'round-white': [styles.round, styles.roundWhite],
  'button-set': [styles.rect, styles.buttonSet],
  'button-set-selected': [styles.rect, styles.buttonSet, styles.buttonSetSelected],
};

export type ButtonProps = StylixProps<'button', CoreButtonProps>;
// TODO necessary?
// & React.ComponentPropsWithoutRef<'button'>;

const Button = React.memo(
  React.forwardRef<HTMLButtonElement, ButtonProps>(function Button(p, ref) {
    const {
      variant = 'blue',
      large,
      small,
      faIcon,
      title,
      linkTo,
      children,
      iconProps,
      trackingLabel,
      trackingContext,
      ...other
    } = p;

    const history = useHistory();

    const variantStyles = variantStylesMap[variant];
    let sizeStyles: any;
    if (['round', 'round-ghost', 'round-gray'].includes(variant)) {
      if (large) sizeStyles = styles.roundLarge;
      else if (small) sizeStyles = styles.roundSmall;
    } else {
      if (large) sizeStyles = styles.rectLarge;
      else if (small) sizeStyles = styles.rectSmall;
    }

    const [otherStyles, otherProps] = useClassifyProps(other);

    return (
      <$.button
        ref={ref}
        display="inline-flex"
        align="center"
        justify="center"
        transition="background 100ms linear, border-color 100ms linear"
        cursor="pointer"
        font="inherit"
        letterSpacing="inherit"
        fontWeight={400}
        {...otherProps}
        $css={[styles.button, variantStyles, sizeStyles, otherStyles, other.$css]}
        onClick={useCallback(
          (e: any) => {
            track('Button', {
              Label: trackingLabel || p.children?.toString?.(),
              Context: trackingContext,
            });
            if (p.linkTo) history.push(p.linkTo);
            other.onClick?.(e);
            other.onClick && e.stopPropagation();
          },
          [history, p.linkTo, other.onClick],
        )}
      >
        {faIcon && (
          <Icon icon={faIcon} title={title} fontSize={17} className="Button-icon" {...iconProps} />
        )}
        {p.children}
      </$.button>
    );
  }),
);

export default Button;

interface ButtonSetProps {
  selected?: any;
  children: Array<React.ReactComponentElement<typeof Button> | null | undefined | false>;
}

export function ButtonSet(p: StylixProps<'div', ButtonSetProps>) {
  const { children, selected, ...other } = p;
  return (
    <$.div
      className={p.className}
      inlineFlex
      border={`2px solid ${colors.grey.disabledBorder}`}
      borderRadius={5}
      overflow="hidden"
      {...other}
    >
      {React.Children.map(children.filter(Boolean), (child) => {
        if (!child) return null;
        if (child.key === selected)
          return React.cloneElement(child, { variant: 'button-set-selected' });
        else return React.cloneElement(child, { variant: 'button-set' });
      })}
    </$.div>
  );
}
